<script>
import { mapGetters } from 'vuex'

export default {
  name: 'StartTeachingSeoContent',
  props: {
    headingClass: {
      type: String,
      default: 'lg:text__headline-5-md--bold text__headline-4-xs--bold'
    }
  },
  computed: {
    ...mapGetters('global', ['getSeoContent'])
  }
}
</script>

<template>
  <section v-if="getSeoContent" class="st-seo-content">
    <div class="st-seo-content__heading" :class="headingClass" v-html="getSeoContent.heading" />
    <p class="st-seo-content__body" v-html="getSeoContent.body" />
  </section>
</template>

<style lang="scss">
.st-seo-content {
  @apply container;
  &__heading {
    @apply text-center text-title mb-6;
  }
  &__body {
    p {
      @apply text__caption-xs--regular lg:text__body-3-md--regular text-body-2 mb-1;
    }
    h2 {
      @apply text__body-2-md--bold text-title mt-6 mb-4;
    }
    h3 {
      @apply text__caption-xs--bold lg:text__body-3-md--bold text-body-2 mt-2 mb-1;
    }
    ul {
      @apply mb-1 list-disc;
      li {
        @apply ml-4;
      }
    }
    ol {
      @apply mb-1 list-disc;
      li {
        @apply ml-4;
      }
    }
  }
}
</style>
